@value mBreakpointSm, mBreakpointLg from '~#src/app/assets/styles/breakpoints.values.css';

.submit-button {
  width: 100%;
}

.header {
  font-size: 18px;
  line-height: 1.33;
  padding-bottom: 16px;

  @media mBreakpointLg {
    font-weight: bold;
  }
}
