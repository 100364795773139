.fallback {
  display: none;
}

.responsive-container {
  container: responsive-container / inline-size;

  &.suspended {
    & > .fallback {
      display: block;
    }

    & > :not(.fallback) {
      display: none;
    }
  }
}
