.container {
  composes: flex flex-col gap-1 bg-light-gray radius-1 p-1 from global;
}

.line-item-container {
  composes: flex from global;
}

.sellable-image-container {
  composes: flex-none flex justify-center items-center mr-1 bg-white from global;
  padding: 2px;
  border-radius: 4px;
  width: 40px;
  height: 40px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.sellable-title {
  composes: inline-block text-black from global;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.variants {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: var(--c-black-75);
}

.clearance-info {
  composes: bg-white radius-1 p-1 mt-1 from global;
}
