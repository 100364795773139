@value bluxomeMediaMd, bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.nav-header {
  background-color: var(--color-expertise-neon-400);
  height: 117px;
  margin-bottom: 24px;

  @media bluxomeMediaLg {
    height: 163px;
    margin-bottom: 56px;
  }
}

.header {
  padding-top: 24px;

  @media bluxomeMediaLg {
    padding-top: 32px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  @media bluxomeMediaLg {
    flex-direction: row;
    gap: 56px;
    border: 1px solid var(--color-neutrals-300);
    border-radius: 12px;
  }
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  @media bluxomeMediaMd {
    width: 320px;
  }
}

.texts {
  flex-grow: 1;
  @media bluxomeMediaMd {
    padding: 32px;
  }
}
