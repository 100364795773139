.wrapper {
  composes: flex from global;
}

.logo {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
}

.button {
  composes: flex flex-auto items-center justify-center font-bold from global;
  padding: 12px 24px;
  border: 1px solid var(--c-black-87);
  font-size: 16px;
  line-height: 1.33;
  color: var(--c-black-87);
  cursor: pointer;
}

.rebranded {
  &.button {
    border: 1px solid var(--color-neutrals-300);
    border-radius: 8px;
    position: relative;
  }
  .logo {
    position: absolute;
    left: 12px;
    top: 12px;
  }
}
