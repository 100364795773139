.content {
  composes: flex flex-auto flex-col from global;
  height: 100%;
  z-index: 1;
}

.fixed-header {
  composes: p-2 from global;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.14);

  & > :not(:first-child) {
    margin-top: 16px;
  }
}

.items {
  composes: flex flex-auto flex-col justify-start px-2 from global;
  overflow-y: auto;

  & > * {
    padding-top: 24px;
  }

  & > :not(:first-child) {
    margin-top: 24px;
    border-top: 1px solid var(--c-black-10);
  }

  & > :last-child {
    padding-bottom: 24px;
  }
}

.line-items > :not(:first-child) {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--c-black-10);
}

.trial-notice {
  composes: flex items-center gap-1 py-1 px-2 bg-black-5 radius-1 mb-2.5 from global;
  font-size: 14px;
}

.info-icon {
  composes: shrink-0 from global;
  fill: var(--c-draft);
}

.trial-info-box-container {
  composes: pt-2.5 mt-2 bt-normal from global;
}
