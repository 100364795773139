@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.view {
  composes: flex flex-col from global;
  height: 100%;
}

.content {
  padding: 40px 32px;

  @media mBreakpointSm {
    padding: 0;
  }
}

.headline {
  composes: font-bold m-0 p-0 mb-1 from global;
  font-size: 22px;
  font-weight: bold;
  color: var(--c-black-100);
}

.sub-headline {
  composes: m-0 p-0 from global;
  font-size: 16px;
  font-weight: normal;
  color: var(--c-black-87);
  margin-bottom: 24px;
}

.childless .sub-headline {
  margin-bottom: 0;
}

.standalone {
  background: #ffffff;

  @media mBreakpointSm {
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.06);
  }

  & .content {
    padding: 40px 32px;

    @media mBreakpointSm {
      padding: 32px 40px;
    }
  }

  & .headline,
  & .sub-headline {
    text-align: center;
  }
}
