@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.view {
  composes: flex flex-col from global;
  height: 100%;
}

.content {
  padding: 40px 32px;

  @media bluxomeMediaMd {
    padding: 0;
  }
}

.top {
  display: grid;
  gap: 8px;
  padding-bottom: 24px;
}

.standalone {
  background: #ffffff;

  @media bluxomeMediaMd {
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.06);
  }

  & .content {
    padding: 40px 32px;

    @media bluxomeMediaMd {
      padding: 32px 40px;
    }
  }

  & .top {
    text-align: center;
  }
}
