@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.values.css';

.inbox {
  composes: flex flex-auto from global;
  overflow: hidden;

  @media mBreakpointMd {
    flex-direction: row;
  }
}

.wrapper {
  composes: flex flex-auto from global;
}

.empty {
  composes: flex flex-auto items-center from global;
  border-left: 1px solid var(--c-black-10);
  flex-direction: column;
  justify-content: center;
}

.column {
  composes: col-12 md-col-3 flex flex-col from global;
  background-color: #fff;
}
