@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.standard-flex-col-container {
  composes: flex flex-col gap-2.5 from global;
}

.page {
  composes: standard-flex-col-container;
  composes: mt-1 mb-3 md-mb-4 from global;
}

.breadcrumbs {
  display: flex;
  gap: 20px;
}

.image-carousel-container {
  margin-bottom: 24px;
}

.image-carousel-skeleton {
  height: 375px;

  @media bluxomeMediaLg {
    height: auto;
    aspect-ratio: 1.2;
  }
}

.shopping-details-container {
  composes: flex flex-col from global;
  gap: 24px;
}

.brand-name-product-title-and-id {
  composes: flex flex-col from global;
  gap: 8px;
}

.product-title-skeleton {
  height: 32px;
  width: 280px;

  @media bluxomeMediaLg {
    padding-left: 4rem;
    height: 38px;
    width: 360px;
  }
}

.pricing {
  composes: flex flex-col from global;
  gap: 4px;
}

.product-price-skeleton {
  height: 32px;
  width: 85px;

  @media bluxomeMediaLg {
    height: 38px;
    width: 200px;
  }
}

.add-to-cart {
  composes: flex flex-col from global;
}

.main-container {
  composes: flex flex-col from global;
  gap: 24px;
}

.accordion-title-skeleton {
  height: 32px;
  width: 240px;

  @media bluxomeMediaLg {
    height: 38px;
    width: 375px;
  }
}

.accordion-title-skeleton-short {
  composes: accordion-title-skeleton;
  width: 190px;

  @media bluxomeMediaLg {
    width: 325px;
  }
}
