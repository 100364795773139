@value mBreakpointSm, mBreakpointMd, bluxomeMediaLtSm from '~#src/app/assets/styles/breakpoints.values.css';

.fixed-body :global(#deal),
.fixed-body :global(#root) {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.content-wrapper {
  composes: flex flex-auto flex-col from global;
}

.content {
  composes: flex flex-auto flex-col relative from global;

  /** This property is dynamically changed based on the header
  appearing and disappearing. It also reflects the real height 
  of header, based on mobile/desktop layout */
  --header-height: 145px;
  /* Ensure main body content does not have a higher z-index than the header */
  z-index: 0;
}

.container {
  composes: flex flex-auto flex-col from global;

  @media mBreakpointSm {
    min-height: initial;
  }
}

.global-banner {
  z-index: 999;
}

.global-banner:has(a[id="farewell-2025-cta"]) {
  @media (max-width: 480px) {
    position: sticky;
    top: 18px;
  }

  @media (max-width: 479px) {
    top: -4px;
  }

  @media (max-width: 401px) {
    top: -19px;
  }

  @media (max-width: 285px) {
    top: -34px;
  }
}
