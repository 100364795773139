.quantity-container {
  composes: flex b-dark-gray from global;
  border-radius: 2px;
  height: 100%;
}

.quantity-button {
  composes: flex justify-center items-center bg-white from global;
  border: none;
  cursor: pointer;
  padding: 0 8px;

  &:disabled {
    cursor: not-allowed;
    background: var(--c-black-5);
  }
}

.quantity-number {
  composes: flex justify-center items-center from global;
  padding: 0 10px;
  border-left: 1px solid var(--c-black-10);
  border-right: 1px solid var(--c-black-10);
  font-size: 14px;
  color: var(--c-black-50);
  cursor: default;
  min-width: 40px;
}
