@value bluxomeMediaMd, bluxomeMediaLg, mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.standard-flex-col-container {
  composes: flex flex-col from global;
  gap: 16px;
  padding: 20px;

  @media bluxomeMediaLg {
    gap: 32px;
  }
}
