.short-supply {
  composes: flex items-center from global;
  color: var(--c-primary);
  padding: 4px 0;
  font-size: 14px;
}

.icon {
  margin-right: 5px;
}
