@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.values.css';

.bubble {
  composes: flex items-center from global;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 18px;

  & .icon {
    width: 40px;
  }
}

.copy {
  composes: flex-auto from global;
  margin-left: 10px;
}
