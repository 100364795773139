@value mBreakpointSm, mBreakpointLg from '~#src/app/assets/styles/breakpoints.values.css';

.message-confirmation-toast-grid-wrapper {
  composes: fixed left-0 right-0 from global;
  z-index: 9999999;
  top: 16px;

  @media mBreakpointLg {
    top: unset;
    bottom: 90px;
  }
}

.message-confirmation-toast-grid-item {
  composes: flex justify-center from global;

  @media mBreakpointLg {
    justify-content: flex-end;
  }
}

.message-confirmation-toast {
  composes: relative p-2 font-bold from global;
  padding-top: 12px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 16px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--c-black-100);
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.27px;
  width: 100%;

  @media mBreakpointSm {
    width: 350px;
  }

  @media mBreakpointLg {
    right: -16px;
    width: 350px;
  }
}

.message-confirmation-toast-expert-presence {
  composes: font-normal from global;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.23px;
}

.message-confirmation-toast-arrow {
  composes: absolute from global;
  display: none;
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid var(--c-black-100);
  right: 0;
  top: 50%;
  margin-top: -16px;
  margin-right: -8px;

  @media mBreakpointLg {
    display: block;
  }
}
