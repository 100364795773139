.fields {
  composes: flex mb-2 from global;
  flex-direction: column;
  gap: 12px;
}

.or {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-block: 24px;

  & hr {
    flex-grow: 1;
    color: var(--color-neutrals-300);
  }
}

.tcpa {
  composes: text-center from global;

  & a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:active {
      color: var(--color-neutrals-black);
    }
  }
}
