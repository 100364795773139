@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.specs-table-heading-row {
  text-align: left;

  & th {
    padding: 32px 0 4px;

    @media bluxomeMediaMd {
      padding: 24px 0;
    }
  }
}

.specs-table-row {
  border-bottom: 1px solid var(--color-neutrals-200);

  & th {
    padding-top: 8px;
  }

  & td {
    padding-bottom: 16px;
  }

  @media bluxomeMediaMd {
    & th,
    & td {
      padding: 24px 0;
    }
  }
}

.spec-name {
  composes: flex items-center gap-1 from global;
  text-align: left;
  height: 100%;

  & > span {
    width: 100%;
  }

  @media bluxomeMediaMd {
    justify-content: space-between;
  }
}

.spec-value {
  composes: flex items-center from global;
  height: 100%;

  & > span {
    width: 100%;
  }

  @media bluxomeMediaMd {
    justify-content: center;
    text-align: center;
  }
}
