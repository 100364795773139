.warning-message-container {
  composes: flex items-center bg-light-gray radius-1 from global;
  min-height: 52px;
  padding: 12px;

  & > svg {
    flex: none;
    margin-right: 12px;
  }
}

.warning-message {
  font-size: 13px;
  line-height: 18px;
}
