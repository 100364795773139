.notification {
  composes: flex justify-center items-center from global;
  color: white;
  font-size: 14px;
  height: 40px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
}

.show {
  transform: scaleY(1);
}

.success-banner {
  background-color: #46a343;
}

.error-banner {
  background-color: #ebaeba;
}
