/**
 * It's unclear what Google considers "content" of the page. To be safe, we don't use
 *   `display: none`, and instead position the content outside the viewport. We also
 *   hide it from screen readers with "aria-hidden".
 */
.off-screen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
