.button {
  composes: relative from global;
}

.loader {
  composes: absolute top-0 right-0 bottom-0 left-0 from global;
  font-size: 10px;
}

.xsmall {
  composes: loader;
  font-size: 6px;
}

.small {
  composes: loader;
  font-size: 8px;
}

.medium {
  composes: loader;
  font-size: 10px;
}

.large {
  composes: loader;
  font-size: 12px;
}

.xlarge {
  composes: loader;
  font-size: 14px;
}

.loading {
  visibility: hidden;
}
