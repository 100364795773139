@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.compare-table-row {
  --compare-table-column-gap: 8px;
  --compare-table-grid-columns: 4;
  display: grid;
  column-gap: var(--compare-table-column-gap);
  grid-template-columns: repeat(var(--compare-table-grid-columns), minmax(0px, 1fr));
  margin: 0 auto;
  max-width: var(--grid-max-width);

  @media bluxomeMediaMd {
    --compare-table-column-gap: 16px;
    --compare-table-grid-columns: 5;
  }
}

.compare-table-header-empty {
  grid-column: span var(--compare-table-grid-columns);
  padding: 0;

  @media bluxomeMediaMd {
    grid-column: span 1;
  }
}

.compare-table-header {
  composes: flex flex-col gap-1 p-0 from global;
  grid-column: span 1;
  text-align: left;

  @media bluxomeMediaMd {
    position: relative;
    padding: 0 8px;
  }
}

.square {
  aspect-ratio: 1;
}

.product-card {
  composes: flex flex-col gap-1.5 from global;
}

.product-card-copy {
  composes: flex flex-col gap-1 from global;
}
