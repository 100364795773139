.container {
  composes: p-2 radius-2 from global;
  background-color: var(--c-draft-pale);
}

.heading {
  composes: mb-2.5 from global;
  color: var(--c-draft);
}

.list {
  /* Need to set the list-style-type and the below ::marker content for screen readers to read the list correctly */
  list-style-type: decimal;
  counter-reset: item;

  & > li {
    counter-increment: item;

    &::marker {
      content: '';
    }
  }
}

.list-item-container {
  composes: flex gap-1 mb-1.5 from global;
}

/* Custom line item "marker" */
.list-item-marker::before {
  display: inline-block;
  width: 24px;
  content: counter(item) '.';
  font-size: 24px;
  font-style: italic;
  color: var(--c-draft);
}

.list-item-content {
  composes: pb-1.5 bb-normal from global;
  font-size: 14px;
}

.list-item-content-flex {
  composes: list-item-content;
  composes: flex flex-col gap-0.5 from global;
}

.separator {
  composes: flex items-center gap-0.5 italic from global;
  font-size: 16px;
  color: var(--c-draft);

  &::before,
  &::after {
    content: '';
    display: inline-block;
    width: 24px;
    border-bottom: 1px solid var(--c-separator-normal);
  }
}

.footer {
  composes: text-black-75 ml-3 from global;
  font-size: 14px;
}

.link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}
