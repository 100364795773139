@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.category-page {
  --horizontal-mobile-gap: 40px;
  --horizontal-desktop-gap: 56px;
}

.section-container {
  composes: flex flex-col from global;
  padding: var(--horizontal-mobile-gap) 0;
  gap: var(--horizontal-mobile-gap);

  @media bluxomeMediaLg {
    padding: var(--horizontal-desktop-gap) 0;
    gap: var(--horizontal-desktop-gap);
  }
}

.expert-callout-section-container {
  composes: section-container;
  background: var(--color-brand-cream);
}

.related-content-section-container {
  composes: section-container;
  background: var(--color-expertise-neon-400);
}
