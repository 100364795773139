.skeleton {
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;

  --base-color: var(--color-neutrals-100);
  --highlight-color: var(--color-neutrals-200);

  animation: skeleton-loader 1.5s linear infinite normal;
  opacity: 50%;
}

@keyframes skeleton-loader {
  0% {
    background-color: var(--base-color);
  }
  50% {
    background-color: var(--highlight-color);
  }
  100% {
    background-color: var(--base-color);
  }
}

@media (prefers-reduced-motion: reduce) {
  .skeleton {
    animation: none;
    background-color: var(--highlight-color);
  }
}

.circle {
  border-radius: 50%;
}

.non-animated {
  background-color: var(--highlight-color);
}
