@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.page {
  width: 100%;
  height: 100%;
  background-color: var(--c-white);

  @media mBreakpointSm {
    width: 50%;
  }
}

.content {
  padding-top: 114px;
  width: 100%;
  height: 100%;
}

.heading {
  composes: text-black mb-2 from global;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;

  @media mBreakpointSm {
    font-size: 32px;
  }
}
