.container {
  composes: flex flex-col items-end from global;
}

.message {
  composes: flex justify-between items-center radius-1 text-dark-gray from global;
  padding: 10px;
  font-size: 14px;
  background: #f6f6f4;
  width: 100%;

  &:not(:first-child) {
    margin-top: 12px;
  }
}

.dismiss-button {
  margin-left: 8px;
  opacity: 0.7;

  & svg {
    width: 9px;
    height: 9px;
  }

  &:hover {
    opacity: 1;
  }
}

.dismiss-all-button {
  margin-top: 4px;
}
