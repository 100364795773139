@value bluxomeMediaMd, bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.column-actions-container {
  composes: flex justify-end items-center gap-1 from global;

  @media bluxomeMediaMd {
    gap: 12px;
  }

  @media bluxomeMediaLg {
    gap: 16px;
  }
}

.button {
  padding: 4px;
  border-radius: 4px;
  cursor: not-allowed;

  &.pinned {
    background: var(--color-neutrals-black);

    & path {
      stroke: var(--color-neutrals-white);
    }
  }
}
