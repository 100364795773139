@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  --action-bar-height: 68px;
  --action-bar-transition-time: 200ms;
  --action-bar-translate-y-visible-position: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: translateY(100%);
  transition: transform var(--action-bar-transition-time) ease-out;
  z-index: 1;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  &.action-bar-hidden {
    --action-bar-translate-y-visible-position: var(--action-bar-height);
  }

  @media bluxomeMediaMd {
    bottom: 24px;
    right: 24px;
    width: fit-content;
    gap: 16px;
    flex-direction: row-reverse;
    transform: translateY(calc(100% + 24px));
  }
}

.container-visible {
  transform: translateY(var(--action-bar-translate-y-visible-position));

  @media bluxomeMediaMd {
    transform: translateY(0);
  }
}

.action-bar {
  height: var(--action-bar-height);
  padding: 12px 16px;
  display: flex;
  gap: 8px;
  background: var(--color-neutrals-white);
  box-shadow: 0px 2px 8px 0px rgba(26, 26, 26, 0.08), 0px 0px 4px 0px rgba(26, 26, 26, 0.08);

  @media bluxomeMediaMd {
    height: unset;
    background: none;
    box-shadow: none;
    padding: 0;
  }
}

.button-container {
  flex: 1 1 0;
  display: flex;

  & > button {
    flex: 1 1 0;
  }

  @media bluxomeMediaMd {
    /* height of chat widget button */
    height: 72px;
  }
}

.compare-button-container {
  composes: button-container;

  @media bluxomeMediaMd {
    position: relative;

    &::before {
      /* Button is transparent, this adds a background */
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 100px;
      background: var(--color-neutrals-white);
    }
  }
}

.compare-button-label-mobile {
  @media bluxomeMediaMd {
    display: none;
  }
}

.compare-button-label-desktop {
  display: none;

  @media bluxomeMediaMd {
    display: inline;
  }
}

.primary-button-container {
  composes: button-container;

  @media bluxomeMediaMd {
    display: none;
  }
}
