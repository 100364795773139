.modal {
  padding: 16px;
  background: url('./background.png');
}

.background {
  height: auto;
}

.pill {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 8px;
  text-transform: uppercase;
  background: var(--color-brand-blue);
  color: var(--color-neutrals-white);
}

.headline {
  margin-top: 16px;
}

.subheadline {
  margin-top: 24px;
}

.cta {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
