.container {
  display: grid;
  gap: 12px;
}

.questions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  & > button {
    text-align: start;
    min-height: 32px;
    border-radius: 16px;

    & > div > div {
      height: auto;
    }
  }
}
