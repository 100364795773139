@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.keyboard-input {
  height: 68px;

  @media bluxomeMediaLg {
    height: 120px;
  }
}

.grid {
  padding-top: 118px;

  @media bluxomeMediaLg {
    padding-top: 100px;
  }
}

.real-grid {
  composes: grid from global;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: var(--grid-gutter-size);
  row-gap: var(--grid-gutter-size);

  @media bluxomeMediaLg {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.result {
  padding-bottom: 100px;
}

.image-skeleton {
  width: 100%;
  aspect-ratio: 1/1;
}
