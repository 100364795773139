.content {
  display: grid;
  gap: 8px;
  margin: 0 16px 16px;
}

.currently-viewing {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-neutrals-200);
  border-radius: 4px;
  padding: 8px;
}

.image {
  max-height: 32px;
  max-width: 40px;
  object-fit: contain;
}

.info {
  display: flex;
  gap: 8px;
}

.info-text {
  display: grid;
  gap: 2px;
}
