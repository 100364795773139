@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.footer {
  background: var(--color-learn-blue-400);
  color: var(--color-neutrals-white);
  padding: 24px 0 100px;

  & a:hover {
    text-decoration: underline;
  }
}

@media bluxomeMediaLg {
  .footer {
    padding: 40px 0 100px;
  }
}

.section-link-list {
  composes: list-reset flex flex-col from global;
  margin-top: 24px;
  gap: 18px;

  & a {
    width: 100%;
  }
}

.social-copyright-and-support-links {
  margin-top: 40px;
}

@media bluxomeMediaLg {
  .social-copyright-and-support-links {
    margin-top: 0;
  }
}

.social-links {
  composes: flex from global;
  gap: 24px;
  margin-bottom: 40px;
}

@media bluxomeMediaLg {
  .social-links {
    margin-bottom: 24px;
    justify-content: end;
  }
}

.copyright {
  display: none;
}

@media bluxomeMediaLg {
  .copyright-and-support-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .copyright {
    display: block;
  }
}

.support-links {
  display: block;
  column-count: 2;
  flex-shrink: 0;

  & a {
    display: block;
    margin-bottom: 14px;
  }
}

@media bluxomeMediaLg {
  .support-links {
    display: flex;
    gap: 24px;

    & a {
      display: inline;
      margin-bottom: 0px;
    }
  }
}

.social-icon {
  width: 24px;
  height: 24px;
}
