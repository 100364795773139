.info-icon {
  margin-right: 10px;
}

.bold {
  composes: font-bold from global;
}

.preorder {
  composes: flex items-center from global;
  font-size: 14px;
  line-height: 1.71;
  color: rgba(26, 26, 26, 0.87);
}
