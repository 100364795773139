@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: var(--stack-level-toast);
}

.banner-background {
  opacity: 0.75;
}

.banner-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;

  @media bluxomeMediaMd {
    flex-direction: row;
    align-items: center;
  }
}

.banner-link {
  text-decoration: underline;
}

.language {
}

.ctas {
  display: flex;
  gap: 6px;
}
