.empty {
  composes: self-center text-center text-black from global;
  z-index: 1;
  padding: 60px 20px;
  font-size: 18px;
  font-weight: 500;
}

.label {
  margin-top: 18px;
}

.sub-label {
  color: var(--c-black-50);
  font-size: 16px;
  font-weight: 400;
}

.icon {
  composes: block mx-auto from global;
  width: 92px;
  height: 84px;
}
