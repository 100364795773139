.row {
  composes: flex flex-col from global;
  border-bottom: 1px solid #f3f4f7;
}

.preview {
  composes: flex flex-auto p-2 from global;
}

.summary {
  composes: p-0 m-0 flex-auto from global;
}

.metadata {
  composes: clearfix from global;
}

.avatar-loader {
  margin-right: 10px;
}

.participants {
  composes: left from global;
}

.timestamp {
  composes: right from global;
}

.last-message {
  margin-top: 5px;
}
