.icon {
  composes: flex items-center justify-center from global;
  width: 100%;
  height: 100%;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
