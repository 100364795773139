@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.modal-content {
  composes: flex flex-col from global;
  height: 100%;
  white-space: pre-line;

  @media mBreakpointSm {
    flex-direction: row;

    &.path-height {
      height: 540px;
    }
  }
}

.content-container {
  width: 100%;
  padding: 24px;

  @media mBreakpointSm {
    padding: 56px 40px;
    min-height: 350px;
  }
}

.title {
  composes: text-black mb-1 font-bold from global;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.25px;

  @media mBreakpointSm {
    width: 75%;
  }
}

.subtitle {
  composes: text-dark-gray mb-3 from global;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.image-container {
  composes: flex from global;
  height: 250px;
  overflow: hidden;

  @media mBreakpointSm {
    height: 100%;
  }
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.submit-button {
  width: 100%;
}

.fine-print {
  composes: text-dark-gray mt-3 from global;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;

  & a {
    color: var(--c-secondary);
  }
}

.loading {
  visibility: hidden;
}

.already-registered {
  margin-top: 24px;
  font-size: 14px;
}

.link {
  color: var(--c-secondary);
}
