@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.cards {
  composes: flex from global;
  gap: 24px;
  width: 100%;
  margin-top: 75px;
  overflow: hidden;
}

.main {
  composes: grid from global;
  grid-template-columns: 1fr;
  gap: 24px;
  height: 400px;
  margin-top: 20px;

  @media mBreakpointSm {
    grid-template-columns: 2fr 1fr;
  }
}
