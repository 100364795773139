.vstack {
  display: flex;
  flex-direction: column;
}

.modal-content {
  composes: vstack;
  padding: 64px 16px 16px;
  gap: 40px;
  background-color: var(--color-expertise-neon-400);
  height: 100%;
}

.header {
  composes: vstack;
  gap: 12px;
  align-items: center;
  padding-bottom: 12px;
  text-align: center;
}

.body {
  composes: vstack;
  gap: 24px;
}

.question {
  composes: vstack;
  gap: 12px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option {
  padding: 12px 16px 12px;
  border-radius: 8px;
  border: 1px solid var(--color-neutrals-300);
  background: var(--color-neutrals-white);

  &.selected {
    border-color: var(--color-learn-blue-500);
    background-color: var(--color-learn-blue-100);
  }
}

.input {
  composes: vstack;
  gap: 16px;
}

.center-aligned {
  text-align: center;
  text-wrap: balance;
}

.resend {
  composes: center-aligned;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4px;
}

.code-input {
  display: flex;
  justify-content: center;

  & > :first-child {
    width: 200px;
  }

  & input {
    text-align: center;
    letter-spacing: 8px;
  }
}

.promo-code {
  composes: vstack;
  align-items: center;
  gap: 8px;

  & input {
    text-align: center;
  }
}

.full-width {
  width: 100%;
}

.underlined {
  text-decoration: underline;
}
