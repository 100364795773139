@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.wrapper {
  composes: flex flex-col from global;
  margin-top: 10px;

  @media mBreakpointSm {
    margin-top: 55px;
    flex-direction: row;
  }
}

.details {
  margin-top: 25px;

  @media mBreakpointSm {
    margin-left: 25px;
    margin-top: 0;
  }
}

.body {
  margin-top: 20px;
}
