.mobile-subscribed-container {
  composes: flex flex-col items-center from global;
}

/* mimics button small success-ghost */
.mobile-subscribed {
  composes: font-bold flex justify-center items-center radius-1 bg-white from global;
  width: 100%;
  padding: 0 20px;
  height: 40px;
  border: 1px solid var(--c-success);
  color: var(--c-success);
  font-size: 16px;

  & svg {
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }
}

/* mimics button xsmall success-link */
.mobile-subscribed-cart {
  composes: flex justify-center items-center from global;
  height: 32px;
  border: 1px solid transparent;
  color: var(--c-success);
  font-size: 14px;

  & svg {
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }
}

.subscribed-button {
  composes: relative from global;

  & svg:first-child {
    margin-right: 12px;
  }

  & svg:last-child {
    margin-left: 0;
  }

  & .unsubscribe-icon {
    width: 12px !important;
    height: 12px !important;
  }

  &:hover {
    color: var(--c-error-hover);
    border-color: var(--c-error-hover);

    & .subscribed-button-normal-content {
      visibility: hidden;
    }

    & .subscribed-button-hover-content {
      visibility: visible;
    }
  }
}

.subscribed-button-cart {
  composes: subscribed-button;
  border: none;

  & svg:first-child {
    margin-right: 6px;
  }

  & .unsubscribe-icon {
    width: 11px !important;
    height: 11px !important;
  }

  & span {
    border-bottom: 2px solid transparent;
  }

  &:hover {
    color: var(--c-error-hover);

    & span {
      border-color: var(--c-error-hover);
    }
  }
}

.subscribed-button-normal-content {
  composes: absolute flex items-center from global;
}

.subscribed-button-mobile-content {
  color: var(--c-success);
}

.subscribed-button-hover-content {
  composes: flex items-center from global;
  visibility: hidden;
}

.mobile-unsubscribe-button {
  margin-top: 8px;
  height: 22px;
}

.mobile-unsubscribe-button-cart {
  height: 14px;
}
