.price {
  composes: mb-1 from global;
  font-size: 14px;
  color: var(--c-black-100);
  font-weight: bold;
}

.free {
  color: #009b3b;
}

.customization {
  font-size: 14px;
  font-weight: 500;
  color: var(--c-black-100);
}

.original-price {
  composes: ml-1 from global;
  color: #a2a6a8;
  font-size: 14px;
  text-decoration: line-through;
}
