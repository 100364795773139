.header {
  margin: 15px 0;
}

.bar {
  margin-top: 45px;
}

.item {
  composes: flex items-center from global;
  margin-top: 50px;
}

.item-details {
  composes: flex from global;
  flex-direction: column;
  margin-left: 15px;
}

.title {
  margin-top: 15px;
}

.item-detail {
  margin-top: 5px;
}
