.remove-button {
  composes: flex justify-center items-center bg-white px-1 py-0 b-dark-gray from global;
  cursor: pointer;
  border-radius: 2px;
  height: 100%;

  &:disabled {
    cursor: not-allowed;
    background: var(--c-black-5);
  }
}
