@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.hero-content-desktop {
  composes: flex flex-col justify-center from global;
  height: 100%;
}

.heading {
  margin-bottom: 16px;
}

.subheading {
  margin-bottom: 16px;

  @media bluxomeMediaMd {
    margin-bottom: 40px;
  }
}

.button {
  margin-top: 24px;
  margin-bottom: 12px;

  @media bluxomeMediaMd {
    margin-top: 0;
  }
}
