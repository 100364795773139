@value mBreakpointSm, mBreakpointLg from '~#src/app/assets/styles/breakpoints.values.css';

.errors {
  composes: mb-2 from global;

  @media mBreakpointLg {
    margin-bottom: 24px;
  }
}

.fields {
  composes: flex mb-2 from global;
  flex-wrap: wrap;
}

.field-wrapper {
  flex-basis: 300px;
  flex-shrink: 1;
  flex-grow: 1;
}

.fake-field-wrapper {
  composes: field-wrapper;
  display: none;

  @media mBreakpointSm {
    display: block;
  }
}

.submit-area {
  composes: flex from global;
}

.or {
  composes: text-center from global;
  width: 100%;
  border-bottom: 1px solid var(--c-black-10);
  line-height: 0px;
  margin: 30px 0;

  & span {
    background: #ffffff;
    padding: 0 10px;
    color: #9f9f9f;
    font-size: 14px;
  }
}

.tcpa {
  composes: m-0 px-3 text-center from global;
  font-size: 14px;
  line-height: 1.71;
  color: var(--c-black-87);

  & a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:active {
      color: var(--c-black-100);
    }
  }

  @media mBreakpointSm {
    margin-top: 1rem;
    padding: 0 1rem 1rem;
  }
}
