@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.modal {
  composes: grid overflow-hidden from global;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;

  @media bluxomeMediaMd {
    grid-template-rows: auto;
    grid-template-columns: 1fr 2fr;
  }

  &:not(:has(.image)) {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto !important;
  }
}

.image {
  aspect-ratio: 352 / 181;

  @media bluxomeMediaMd {
    aspect-ratio: 306 / 391;
  }

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.modal-content {
  composes: flex flex-col items-start justify-center from global;
  padding: 24px 16px;

  @media bluxomeMediaMd {
    padding: 56px 40px;
  }
}

.cta {
  composes: self-stretch from global;
  margin: 16px 0;

  @media bluxomeMediaMd {
    margin: 40px 0 0;
    align-self: flex-start;
  }
}
