.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--c-black-100);
  margin-bottom: 4px;
}

.subtitle {
  composes: mb-2 from global;
  font-size: 14px;
  line-height: 20px;
  color: var(--c-black-75);
}

.messages > :not(:last-child) {
  margin-bottom: 8px;
}

.message-container {
  composes: flex p-1 b-dark-gray radius-2 from global;
}

.image-container {
  margin-right: 12px;
  flex: none;
  width: 40px;
  height: 40px;

  & > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.sellable-details {
  margin-right: 2px;
  flex: auto;
}

.sellable-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-black-100);
  margin-bottom: 4px;
}

.sellable-variants {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: var(--c-black-50);
}

.buttons-container {
  composes: flex justify-start flex-wrap from global;

  & > :not(:last-child) {
    margin-right: 10px;
  }
}

.dismiss-icon {
  flex: none;
  margin: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
