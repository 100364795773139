@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.email-or-phone-login-form {
  composes: mt-2 from global;
  margin-bottom: 24px;
}

.email-or-phone-login-form-field {
  padding-bottom: 24px;
}

.email-or-phone-headline {
  composes: text-center from global;
  margin-bottom: 24px;
  font-size: 14px;
  color: #8c8c8c;
}

.email-or-phone-field {
  composes: email-or-phone-login-form-field;
}

.email-or-phone-button {
  width: 100%;
}

.password-field-wrapper {
  composes: relative from global;
  overflow: hidden;
}

.password-field {
  composes: email-or-phone-login-form-field;
}

.password-field input {
  padding-right: 80px !important;
}

.inline-reset-password-link {
  composes: absolute from global;
  color: #0c5aaa;
  font-size: 16px;
  line-height: 24px;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.error-reset-password-link {
  composes: underline italic from global;
  color: blue;
  cursor: pointer;
}

.submit-area {
  composes: flex from global;
}

.submit-button {
  width: 100%;
}
