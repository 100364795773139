.or {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-block: 24px;

  & hr {
    flex-grow: 1;
    color: var(--color-neutrals-300);
  }
}
