.textfield {
  display: flex;
  gap: 8px;

  > :first-child {
    flex-basis: 100%;
  }
}

.send-button {
  color: var(--color-neutrals-600);
  transition: scale 0.2s;

  /* Enlarge the button for easier tapping, 
      but translate it back so the scale animation does not move it */
  padding-inline: 12px;
  transform: translateX(6px);

  &:hover {
    color: var(--color-learn-blue-600);
    scale: 1.4;
  }
}
