@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.takeover {
  composes: fixed top-0 right-0 bottom-0 left-0 flex flex-col items-center from global;
  background-color: #000;
  z-index: 10000;
}

.content {
  color: #fff;
  margin-top: 60px;
  padding: 0 20px;

  @media mBreakpointSm {
    margin-top: 130px;
    max-width: 550px;
  }
}

.break {
  width: 56px;
  height: 4px;
  background-color: #62727a;
  margin-bottom: 40px;
  margin-top: 48px;
}

.title {
  composes: mb-2 from global;
  font-size: 24px;
}

.message {
  composes: mb-3 from global;
  font-size: 18px;
  line-height: 1.78;
  color: var(--c-black-25);
}

.button {
  composes: ml-auto mt-1 mr-1 from global;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
