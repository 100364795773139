/**
  * We're penalized on Google for our font download timelines. Setting
  * font display to `swap` will mitigate latency during the font download.
  * https://developers.google.com/web/updates/2016/02/font-display
  */
@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-book.woff2') format('woff2'),
    url('./fonts/circular-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-book.woff2') format('woff2'),
    url('./fonts/circular-book.woff') format('woff');
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-bold-italic.woff2') format('woff2'),
    url('./fonts/circular-bold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-medium.woff2') format('woff2'),
    url('./fonts/circular-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-black-italic.woff2') format('woff2'),
    url('./fonts/circular-black-italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-book-italic.woff2') format('woff2'),
    url('./fonts/circular-book-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-medium-italic.woff2') format('woff2'),
    url('./fonts/circular-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-bold.woff2') format('woff2'),
    url('./fonts/circular-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-black.woff2') format('woff2'),
    url('./fonts/circular-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Vollkorn';
  src: url('./fonts/vollkorn-regular.woff2') format('woff2'),
    url('./fonts/vollkorn-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Vollkorn';
  src: url('./fonts/vollkorn-regular.woff2') format('woff2'),
    url('./fonts/vollkorn-regular.woff') format('woff');
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Vollkorn';
  src: url('./fonts/vollkorn-semibold.woff2') format('woff2'),
    url('./fonts/vollkorn-semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Vollkorn';
  src: url('./fonts/vollkorn-bold.woff2') format('woff2'),
    url('./fonts/vollkorn-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Bogle';
  src: url('./fonts/bogle-regular.woff2') format('woff2'),
    url('./fonts/bogle-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Bogle';
  src: url('./fonts/bogle-regular.woff2') format('woff2'),
    url('./fonts/bogle-regular.woff') format('woff');
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Bogle';
  src: url('./fonts/bogle-bold.woff2') format('woff2'),
    url('./fonts/bogle-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
