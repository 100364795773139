@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.banner {
  composes: radius-2 py-1 px-2 mb-3 from global;
  background: var(--c-success-pale);

  & > span {
    vertical-align: middle;
    padding-left: 16px;
    color: var(--c-success);
  }
}

.banner-alt {
  composes: pl-0 from global;
  background: var(--c-black-5);

  & > span {
    font-weight: 450;
    color: var(--c-black-75);
  }
}

.title {
  composes: font-bold from global;
  font-size: 32px;
}

.subtitle {
  composes: text-black-75 py-1 from global;
  font-weight: 450;
}

.url {
  composes: underline from global;
}

.list {
  composes: py-2 px-2.5 from global;

  & li.list-item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.content {
  composes: flex flex-wrap justify-center from global;
}

.embedded-video {
  composes: mt-3 mb-2.5 from global;
}

.redo {
  composes: mb-1 from global;
}
