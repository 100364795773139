.or {
  composes: text-center from global;
  width: 100%;
  border-bottom: 1px solid var(--c-black-10);
  line-height: 0px;
  margin: 30px 0;

  & span {
    background: #ffffff;
    padding: 0 10px;
    color: #9f9f9f;
    font-size: 14px;
  }
}
