@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.values.css';

/* Icon component */

.icon-container {
  composes: flex flex-col from global;
  align-items: center;
  font-size: 14px;
  margin-bottom: 4px;
}

.browser-link {
  composes: font-bold mb-2 mt-1 from global;
  color: #087bcc;
}

.icon {
  composes: pb-1 from global;
  height: 72px;
}

/* Upgrade Modal */

.container {
  height: 100%;
  background-color: #000000;
  color: #ffffff;

  border-top: 4px solid var(--c-primary);
}

.logo-bar {
  composes: flex justify-center from global;
  padding: 24px 0px;
  width: 100%;
}

.modal-body {
  composes: flex flex-col items-center mx-2 pb-2 from global;
  border-bottom: 1px solid #ffffff;
}

.header {
  composes: font-bold from global;
  line-height: 32px;
  font-size: 24px;
  text-align: center;
}

.text-container {
  padding: 16px 0px 24px 0px;
  width: 100%;
}

.modal-body-wrapper {
  composes: flex from global;
  justify-content: center;
  text-align: center;
}

.iconRow {
  composes: flex from global;
  justify-content: space-around;

  @media mBreakpointMd {
    width: 50%;
  }
}

.all-icons {
  width: 100%;

  @media mBreakpointMd {
    display: flex;
  }
}

.footer {
  composes: py-2 from global;
  color: #087bcc;
  font-size: 14px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}
