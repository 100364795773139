.content {
  composes: flex flex-col bg-white items-center text-center from global;
  padding: 28px 32px 25px;
  border-radius: 13px;
}

.title {
  composes: font-bold from global;
  font-size: 24px;
  line-height: 1.25;
}

.description {
  composes: mt-1 mb-2 from global;
  font-weight: 450;
  font-size: 16px;
  color: var(--c-black-75);
}

.details {
  composes: mt-3 from global;
  font-weight: 450;
  font-size: 12px;
  line-height: 1.58;
}

.background-content {
  composes: flex flex-col items-center justify-between p-2 from global;
}

.link {
  color: var(--c-secondary);
}
