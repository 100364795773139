.grid {
  composes: fixed top-0 left-0 right-0 bottom-0 from global;
  z-index: 99999999;
  overflow: hidden;
  pointer-events: none;
}

.column {
  composes: flex items-center justify-center from global;
  background: rgba(243, 172, 142, 0.55);
  height: 100vh;
  font-size: 50px;
  color: #fff;
}
