@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.six-digit-code-field {
  composes: flex from global;
  margin-bottom: 24px;
}

.error {
  margin-bottom: 24px;
}

.error-request-code-link {
  composes: underline italic from global;
  color: blue;
  cursor: pointer;
}

.resend {
  composes: text-center from global;
  color: var(--c-black-87);
  font-size: 16px;
}

.resend-link {
  color: #0c5aaa;
}

.resend-link-in-progress {
  color: var(--c-black-87);
}
