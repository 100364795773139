.header {
  composes: py-1 mt-2 font-bold text-center from global;
}

.logo {
  height: 34px;
  width: 176px;
}

.image {
  composes: mx-auto col-12 sm-col-8 md-col-8 lg-col-8 mx-auto mt-3 from global;

  & > img {
    width: 100%;
  }
}

.headline {
  composes: m-0 p-0 pt-3 text-center from global;
  color: #1b242a;
  font-size: 28px;
}

.body {
  composes: m-0 p-0 pt-2 text-center from global;
  font-size: 20px;
  color: #5a6b76;
}

.action {
  composes: flex justify-center my-3 from global;
}
