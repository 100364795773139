@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.button {
  --expert-pill-background-color: var(--color-learn-blue-400);
  --expert-pill-avatar-size: 56px;
  --expert-pill-border-radius: 36px;
  --expert-pill-gap: 8px;
  --expert-pill-margin: 16px;
  /* the pill has an 8px padding, but the avatar is positioned absolute for collapsing purposes */
  --expert-pill-visual-padding: 8px;
  --expert-pill-visual-padding-right: 16px;
  /* this is the actual horizontal padding to account for the positioned absolute avatar */
  --expert-pill-actual-padding-x: calc(
    var(--expert-pill-visual-padding) + (var(--expert-pill-avatar-size) / 2)
  );
  --expert-pill-chat-label-width: 78px;

  position: relative;
  padding: var(--expert-pill-visual-padding) var(--expert-pill-actual-padding-x);
  background: var(--expert-pill-background-color);
  height: 72px;
  border-radius: var(--expert-pill-border-radius);
  cursor: pointer;
  box-shadow: var(--elevation-3);
  margin: 0px var(--expert-pill-margin) var(--expert-pill-margin);
  align-self: flex-end;

  &.hovered {
    --expert-pill-background-color: var(--color-learn-blue-500);
  }

  &.pressed {
    --expert-pill-background-color: var(--color-learn-blue-600);
  }

  @media bluxomeMediaMd {
    margin: 0px;
  }
}

.expert-avatar-container {
  position: absolute;
  top: var(--expert-pill-visual-padding);
  left: var(--expert-pill-visual-padding);
}

.unread-messages-count {
  composes: absolute flex justify-center items-center from global;
  top: -5px;
  left: -8px;
  border-radius: 100px;
  border: 2px solid var(--color-neutrals-white);
  background: var(--color-shop-orange-400);
  width: 22px;
  height: 22px;
  text-align: center;
}

.placeholder-expert-avatar {
  composes: flex justify-center items-center from global;
  background: var(--color-brand-blue);
  border-radius: var(--expert-pill-border-radius);
  width: var(--expert-pill-avatar-size);
  height: var(--expert-pill-avatar-size);
}

.expert-details-with-chat-pill-container {
  composes: flex justify-between items-center from global;
  gap: var(--expert-pill-gap);
  text-align: left;
  padding-right: calc(
    var(--expert-pill-chat-label-width) + var(--expert-pill-gap) +
      var(--expert-pill-visual-padding-right) -
      ((var(--expert-pill-avatar-size) / 2) + var(--expert-pill-visual-padding))
  );
  padding-left: calc((var(--expert-pill-avatar-size) / 2) + var(--expert-pill-gap));
  /* pill width is determined by this container's width */
  width: calc(100vw - ((var(--expert-pill-margin) + var(--expert-pill-actual-padding-x)) * 2));

  @media bluxomeMediaMd {
    width: calc(360px - (var(--expert-pill-actual-padding-x) * 2));
  }
}

.expert-details-container {
  width: 100%;
}

.expert-details-container-less-right-padding {
  padding-right: 0px;
}

.title-outer-container {
  composes: relative flex items-center from global;
}

.title-inner-container {
  opacity: 0;
  transition: opacity 250ms linear;

  &.visible {
    opacity: 1;
    transition-delay: 250ms;
  }
}

.unread-count-container {
  composes: absolute top-0 left-0 from global;
  composes: title-inner-container;
}

.expert-rating {
  composes: flex items-center mt-0.5 from global;
  gap: 2px;
}

.star-icon {
  color: var(--color-support-marketing-mango);
}

.empty-star-icon {
  color: var(--color-neutrals-white);
  opacity: 0.4;
}

.department-expert-rating {
  composes: flex items-center from global;
}

.chat-label-container {
  width: var(--expert-pill-chat-label-width);
  position: absolute;
  right: var(--expert-pill-visual-padding-right);
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.chat-label {
  flex: none;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid var(--color-neutrals-white);
  padding: 0 12px;
}

.icon {
  flex: none;
}

.expert-ai-avatar {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background-color: var(--color-learn-blue-400);
}
