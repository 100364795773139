.title {
  composes: font-bold from global;
  font-size: 32px;
}

.subtitle {
  composes: text-black-75 py-1 from global;
  font-weight: 450;
}

.error-title {
  composes: font-bold from global;
  font-size: 24px;
}

.error-subtitle {
  composes: py-1 from global;
  font-weight: 450;
}

.link {
  composes: underline from global;
}
