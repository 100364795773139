.normal-o-auth {
  composes: flex flex-col justify-center from global;
}

.rebranded-o-auth {
  composes: flex flex-col justify-center from global;
  gap: 12px;
}

.rebranded-o-auth-button {
  composes: flex-auto from global;
}

.normal-o-auth-button {
  composes: flex-auto from global;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
