.container {
  composes: flex gap-0.5 items-start body-small text-black from global;
  line-height: 16px;

  & > svg {
    flex-shrink: 0;
  }
}

.title {
  composes: font-bold text-black from global;
}
