@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.banner {
  composes: flex mb-2 items-center from global;
  gap: 8px;
}

.content {
  composes: text-white from global;
  background: var(--c-draft);
  border-radius: 32px;
  padding: 2px 12px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;

  @media mBreakpointSm {
    max-width: none;
  }
}

.terms {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: var(--c-black-50);
}
