@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  composes: flex flex-col from global;
  height: 100%;
}

.body {
  overflow-y: auto;
  flex: auto;
  display: flex;
  flex-direction: column-reverse;
}

.conversation {
  composes: flex items-start from global;
  padding: 0 1rem;
  width: 100%;
}

.avatar {
  composes: flex-none self-start from global;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.messages {
  composes: flex flex-col from global;
  min-width: 0;
  width: 100%;
  padding-bottom: 1rem;
}

.text-message-bubble {
  margin-top: 4px;
  margin-bottom: 0;
  word-break: break-word;
  white-space: pre-line;
  box-shadow: none;
  background: transparent;
  font-size: 15px;
  line-height: 150%;
  width: 100%;
  color: #1a1a1a;
  border-radius: 0;

  & a {
    color: inherit;
    text-decoration: underline;
    font-style: italic;
  }

  @media bluxomeMediaMd {
    max-width: 768px;
  }
}

.sender {
  composes: flex items-end font-bold from global;
  margin-bottom: 2px;
  margin-left: 1px;
  font-size: 15px;
  color: var(--c-black-100);
}

.label {
  composes: flex items-center font-bold text-center from global;
  padding: 24px 1em;
  font-size: 12px;
  color: #626262;
}

.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 0;
  flex-grow: 1;
}

.date {
  composes: font-bold mx-1 from global;
}

.loader {
  composes: flex items-center justify-center from global;
  padding: 0 1em 1em;
}
