.wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.progress {
  padding-top: 12px;
}

.link {
  text-decoration: underline;
}
