.collapsible-outer-container {
  display: grid;

  &.collapsed .collapsible-inner-container {
    opacity: 0;
    transition: none;
  }
}

.collapsible-horizontal-outer-container {
  grid-template-columns: 1fr;
  transition: grid-template-columns 150ms linear;
  will-change: grid-template-columns;

  &.collapsed {
    grid-template-columns: 0fr;
  }
}

.collapsible-vertical-outer-container {
  grid-template-rows: 1fr;
  transition: grid-template-rows 150ms linear;
  will-change: grid-template-rows;

  &.collapsed {
    grid-template-rows: 0fr;
  }
}

.collapsible-inner-container {
  overflow: hidden;
  opacity: 1;
  /* Update the content opacity after the button is finished expanding to prevent showing a jittery animation */
  transition: opacity 100ms linear 150ms;
}
