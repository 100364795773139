@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.review-item {
  height: 360px;

  @media bluxomeMediaMd {
    height: 196px;
  }
}
