.text {
  composes: relative inline-block from global;
  padding-bottom: calc(2em / 3);
}
/* The underline */
.underline {
  composes: my-0 from global;
  content: '';
  position: absolute;
  bottom: -2px;
  height: 2px;
  width: 50px;
  background-color: currentColor;
  border: none;

  /* Alignment */
  &.align-left {
    left: 0;
  }
  &.align-right {
    right: 0;
  }
  &.align-center {
    left: 50%;
    margin-left: -25px;
  }
}
