.message-list {
  composes: block flex-auto justify-start m-0 from global;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;

  /* IE does not calculate a childs layout correctly if no height is specified  */
  flex-basis: 0;
}

.group {
  composes: mb-2 px-2 from global;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-bottom: 0;
  }
}

.columns {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.avatar {
  composes: self-start from global;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.flat {
  margin-bottom: 24px;
  padding-top: 2px;
}

.message {
  box-shadow: none !important;
}

.sender {
  composes: flex items-center font-bold from global;
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
  align-items: flex-end;
  color: var(--c-black-100);
}

.not-available {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--c-black-4);
  padding: 10px;
  margin-bottom: 10px;
  color: rgba(26, 26, 26, 0.75);
}

.message-text {
  padding-top: 2px;
  & > div {
    padding-top: 5px;
  }
}

.connect-me-button-row {
  composes: flex justify-start font-bold from global;
  margin: 10px 0px;
}

.connect-me-button-box {
  text-align: center;
  width: 284px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--c-black-4);
  background-color: #ffffff;
  padding: 5px;
}

.connect-me-button {
  width: 100%;
  border-radius: 4px;
  background-image: linear-gradient(to right, #f94d1f, #d34168);
}
