@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.email-or-phone-login-form {
  composes: mt-2 from global;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.password-field-wrapper {
  composes: relative from global;
  overflow: hidden;
}

.inline-reset-password-link {
  composes: absolute from global;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.error-reset-password-link {
  composes: underline italic from global;
  color: var(--color-learn-blue-600);
  cursor: pointer;
}
