@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.six-digit-code-field {
  composes: flex from global;
  margin-bottom: 24px;
}

.error {
  margin-bottom: 24px;
}

.error-request-code-link {
  composes: underline italic from global;
  color: blue;
  cursor: pointer;
}

.new-password-headline {
  composes: font-bold text-center m-0 p-0 mb-2 from global;
  font-size: 22px;
  font-weight: bold;
  color: var(--c-black-100);
}

.new-password {
  composes: flex mb-2 from global;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media mBreakpointSm {
    flex-direction: row;
  }
}

.new-password-item {
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 1;
}

.submit-area {
  composes: flex from global;
}

.submit-button {
  width: 100%;
}
