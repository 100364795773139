@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  composes: flex flex-col gap-3 from global;
  padding: 2rem var(--grid-margin-size);
}

.specs-table-action-header-container {
  composes: flex justify-between gap-2.5 from global;
}

.specs-table-action-header-left-container {
  composes: flex justify-between gap-2.5 from global;

  @media bluxomeMediaMd {
    justify-content: flex-start;
  }
}

.specs-table {
  width: 175%;

  @media bluxomeMediaMd {
    width: 100%;
  }
}
