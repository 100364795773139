@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.compare-table-row {
  position: relative;
  display: grid;
  gap: 16px 8px;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  margin: 0 auto;
  /* Use global grid variable to mimic grid margins */
  max-width: var(--grid-max-width);

  @media bluxomeMediaMd {
    column-gap: 16px;
    grid-template-columns: repeat(5, minmax(0px, 1fr));
  }

  &.empty-data-cells {
    row-gap: 0;
  }
}

.compare-table-header {
  grid-column: span 2;
  padding: 0;

  &.sticky-left {
    position: sticky;
    width: fit-content;
    left: 16px;
  }

  &.sticky-center {
    position: sticky;
    width: fit-content;
    /* 58px is half the width of the show more button */
    left: calc(50% - 58px);
  }

  @media bluxomeMediaMd {
    grid-column: span 1;
  }
}

.compare-table-data {
  grid-column: span 1;
  padding: 0;

  &:first-of-type {
    grid-column: 1 / span 1;

    @media bluxomeMediaMd {
      grid-column: span 1;
      padding-right: 8px;
      padding-left: 8px;

      &.first-data-column-dropshadow {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          top: 0;
          bottom: 0;
          left: -8px;
          width: 8px;
          opacity: 0.14;
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 100%);
        }

        &::after {
          content: '';
          position: absolute;
          display: inline-block;
          top: 0;
          bottom: 0;
          right: -8px;
          width: 8px;
          opacity: 0.14;
          background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
    }
  }
}
