@value bluxomeMediaLg from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.path-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.fixed-min-height {
  @media bluxomeMediaLg {
    min-height: 540px;
    height: 100%;
  }
}
