@value bluxomeMediaMd, bluxomeMediaLtMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  --chat-widget-transition-time: 200ms;
  z-index: var(--stack-level-modal-underlay);
}

.overlay {
  composes: fixed top-0 right-0 bottom-0 left-0 from global;
  background: var(--color-overlay-solid);
  transition: opacity var(--chat-widget-transition-time) ease-out;
  transform: translateY(100%);
  opacity: 0;
  display: none;
}

.overlay-visible {
  transform: translateY(0);
  opacity: 1;
  transition-delay: var(--chat-widget-transition-time);

  @media bluxomeMediaLtMd {
    display: block;
  }
}

.chat-widget-container {
  composes: flex flex-col fixed right-0 bottom-0 left-0 from global;
  background: var(--color-neutrals-white);
  width: 100vw;
  height: calc(100% - 32px);
  max-height: 100%;
  border-radius: 9px 9px 0px 0px;
  box-shadow: 0px 1.5px 6px 0px rgba(26, 26, 26, 0.16), 0px 0px 3px 0px rgba(26, 26, 26, 0.08);
  transform: translateY(100%);
  transition: transform var(--chat-widget-transition-time) ease-out, max-height 200ms;
  overflow: hidden;

  @media bluxomeMediaMd {
    right: 24px;
    left: unset;
    width: 400px;
    height: clamp(300px, 65vh, 800px);
  }
}

.chat-widget-expanded {
  transform: translateY(0);
  transition-delay: var(--chat-widget-transition-time);
}

.chat-widget-preview {
  composes: chat-widget-expanded;
  max-height: 50%;

  @media bluxomeMediaMd {
    max-height: unset;
  }
}

.chat-header {
  composes: flex justify-between items-center gap-2 py-1 px-2 cursor-pointer from global;
  background: var(--color-learn-blue-400);

  &:hover {
    background: var(--color-learn-blue-500);
  }
}

.chat-header-actions {
  composes: flex items-center gap-1 from global;
}

.chat-container {
  composes: flex flex-col justify-end from global;
  /* header height + divider = 49px */
  height: calc(100% - 49px);
}
