.profile {
  composes: flex flex-col p-2 mb-auto from global;
  flex-shrink: 0;
}

.identity {
  composes: flex items-center mb-2 from global;
}

.avatar {
  composes: mr-1 from global;
  flex: none;
}

.intro {
  composes: flex flex-col from global;
}

.name {
  composes: font-bold from global;
  font-size: 24px;
  color: #233034;
  line-height: 1.33;
}

.headline {
  color: #3d4e56;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.section {
  padding: 20px 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &:first-child {
    padding-top: 0;
  }
}

.highlights {
  composes: section;
  composes: list-reset m-0 from global;
}

.highlight {
  composes: flex items-center from global;
  margin: 4px 0;
  font-size: 14px;
  line-height: 1.71;
  color: #233034;
}

.highlight-icon {
  composes: mr-1 from global;
  width: 15px;
  height: 15px;
}

.highlight-value {
  composes: mr-1 font-bold from global;
  flex-shrink: 0;
}

.highlight-secondary-value {
  color: #3d4e56;
}

.section-title {
  composes: font-bold from global;
  font-size: 18px;
  line-height: 1.78;
  color: #233034;
}

.link {
  margin-top: 16px;
}

.expert-profile-link {
  color: #087bcc;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.view-link {
  margin-top: 16px;
}
