* {
  box-sizing: border-box;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

:root {
  container: root / inline-size;
}

html,
body {
  height: 100%;
  width: 100%;
}

/* body and #deal need to be flex containers/children for IE to work correctly */
body {
  font-display: swap;
  font-family: 'FT Polar', system-ui, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

:global(#root),
:global(#deal) {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
  min-height: 0;
  min-width: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-display: swap;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.toast {
  z-index: 99999999;
}

@keyframes glowing {
  0% {
    box-shadow: inset 0 0 -10px #ffda94;
  }
  40% {
    box-shadow: inset 0 0 40px #ffda94;
  }
  60% {
    box-shadow: inset 0 0 40px #ffda94;
  }
  100% {
    box-shadow: inset 0 0 -10px #ffda94;
  }
}

.impersonation-overlay {
  composes: fixed top-0 left-0 right-0 bottom-0 from global;
  z-index: 999999999;
  pointer-events: none;
  animation: glowing 5000ms infinite;
}

:root {
  /* These colors are the ones currently in use. We override some of them with the Bluxome palette */
  --c-primary: var(--color-shop-orange-400);
  --c-primary-hover: var(--color-shop-orange-500);
  --c-primary-dark: var(--color-shop-orange-600);
  --c-primary-light: var(--color-shop-orange-300);
  --c-primary-pale: var(--color-shop-orange-200);
  --c-primary-gradient-shade: var(--color-shop-orange-400);
  --c-primary-gradient-orange: var(--color-shop-orange-400);
  --c-primary-gradient-red: var(--color-shop-orange-400);
  --c-secondary: var(--color-learn-blue-400);
  --c-secondary-hover: var(--color-learn-blue-500);
  --c-secondary-dark: var(--color-learn-blue-600);
  --c-secondary-light: var(--color-learn-blue-300);
  --c-secondary-pale: var(--color-learn-blue-200);
  --c-secondary-gradient-shade: var(--color-learn-blue-400);
  --c-secondary-gradient-blue: var(--color-learn-blue-400);
  --c-secondary-gradient-light-blue: var(--color-learn-blue-400);
  --c-success: var(--color-feedback-success-400);
  --c-success-hover: var(--color-feedback-success-500);
  --c-success-light: var(--color-feedback-success-300);
  --c-success-pale: var(--color-feedback-success-200);
  --c-error: var(--color-feedback-critical-400);
  --c-error-hover: var(--color-feedback-critical-500);
  --c-error-light: var(--color-feedback-critical-300);
  --c-error-pale: var(--color-feedback-critical-200);
  --c-warning: var(--color-feedback-warning-400);
  --c-warning-hover: var(--color-feedback-warning-500);
  --c-warning-light: var(--color-feedback-warning-300);
  --c-warning-pale: var(--color-feedback-warning-200);
  --c-black-100: var(--color-neutrals-black);
  --c-black-95: var(--color-neutrals-black);
  --c-black-99: var(--color-neutrals-black);
  --c-black-87: var(--color-neutrals-black);
  --c-black-75: var(--color-neutrals-600);
  --c-black-50: var(--color-neutrals-600);
  --c-black-25: var(--color-neutrals-300);
  --c-black-10: var(--color-neutrals-300);
  --c-black-5: var(--color-neutrals-200);
  --c-black-4: var(--color-neutrals-100);
  --c-black-3: var(--color-neutrals-100);
  --c-white: var(--color-neutrals-white);
  --c-draft: #5000a4;
  --c-draft-pale: #efebfa;
  --c-review: #5e4c00;
  --c-review-pale: #f8f3de;
  --c-rejected: #a40700;
  --c-rejected-pale: #faeceb;
  --c-approved: #045;
  --c-approved-pale: #dcf0fb;
  --c-published: #005502;
  --c-published-pale: #e6f6ec;
  --c-separator-light: var(--color-neutrals-200);
  --c-separator-normal: var(--color-neutrals-300);
  --c-separator-dark: var(--color-neutrals-300);
}
