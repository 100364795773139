.cart {
  composes: flex flex-col flex-auto from global;
}

.tabs {
  composes: flex items-center from global;
  border-bottom: 1px solid var(--c-black-10);
  padding: 24px 24px 16px;
}

.tab {
  composes: mr-3 from global;
  font-size: 16px;
  cursor: pointer;
  color: rgba(26, 26, 26, 0.6);

  &:last-child {
    margin-right: 0;
  }
}

.tab-active {
  color: var(--c-black-100);
  text-decoration: underline;
  text-decoration-color: var(--c-secondary);
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

.loading-header {
  height: 2px;
  width: 100%;
  background: orange;

  & > :not(:first-child) {
    margin-top: 16px;
  }
}

.loader {
  composes: absolute from global;
  width: 100%;
  height: 3px;
  overflow-x: hidden;
}

.loader-line {
  composes: absolute from global;
  opacity: 0.4;
  background: var(--c-secondary);
  width: 150%;
  height: 3px;
}

.loader-subline {
  composes: absolute from global;
  background: var(--c-secondary);
  height: 3px;
}

.loader-inc {
  composes: loader-subline;
  animation: increase 2s infinite;
}

.loader-dec {
  composes: loader-subline;
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
