@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.prompt {
  composes: p-2 fixed bottom-0 from global;
  width: 100%;
  z-index: 999999999;
  border-top: 1px solid var(--c-black-10);
  box-shadow: 0px 4px 20px 2px rgb(0 0 0 / 15%);
  background: #fff;

  @media mBreakpointSm {
    width: auto;
    max-width: 400px;
    left: 110px;
    top: 0;
    bottom: unset;
    border: 1px solid var(--c-black-10);
    border-top: 0;
    border-radius: 0 0 2px 2px;
    box-shadow: 0px 9px 20px -2px rgb(0 0 0 / 15%);
  }
}

.title {
  composes: font-bold from global;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
}

.sub-title {
  font-size: 16px;
  line-height: 20px;
  color: rgba(26, 26, 26, 0.75);
}

.buttons {
  composes: mt-2 flex justify-end from global;
}

.no-thanks-button {
  composes: mr-1 from global;
}
