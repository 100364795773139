.composer {
  composes: fit flex flex-auto from global;
  flex: 0 0 auto;
  font-size: 16px;
  border-top: 1px solid #1a1a1a;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;

  &::placeholder {
    color: #b4c6d3;
  }
}

.text-area-wrapper {
  composes: flex justify-start px-2 py-1.5 from global;
  flex: 1 0;
  min-width: 0;
}

.resizing-input {
  composes: flex-auto my-1 border-none from global;
  overflow: hidden auto;
  overflow-wrap: break-word;
  background: transparent;
  flex-shrink: 999;
  outline: none;
  font-size: 16px;
  line-height: 150%;
  resize: none !important;
  max-height: 150px;

  &::placeholder {
    color: #b4c6d3;
  }

  &:disabled {
    color: #666666;
  }
}

.send {
  composes: font-bold m-1 px-0 py-1 border-none from global;
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  color: #087bcc;

  &:disabled {
    color: #666666;
    cursor: not-allowed;
  }
}
