.container {
  composes: flex flex-col gap-1 from global;
  z-index: 1;
}

.line-item {
  composes: flex from global;
  flex-shrink: 0;
}

.image-container {
  composes: flex relative from global;
  width: 80px;
  min-width: 80px;
  max-height: 150px;
}

.trial-pill {
  composes: absolute from global;
  border: 2px solid var(--c-white);
}

.image {
  object-fit: contain;
  object-position: 50% 0;
  height: 100%;
}

.image-padding {
  composes: pt-1 pl-2 from global;
}

.link {
  width: 100%;
}

.details {
  composes: flex-auto from global;
  padding-left: 10px;
}

.margin {
  margin-bottom: 5px;
}

.title {
  composes: block from global;
  composes: margin;
  font-size: 14px;
  color: #182026;
  font-weight: 500;
}

.line-item-info-container {
  composes: mt-1 from global;

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
}

.quantity-container {
  composes: flex items-center my-1 from global;
  height: 24px;

  & > :not(:last-child) {
    margin-right: 12px;
  }
}

.trial-price-details {
  composes: flex flex-col from global;
  gap: 4px;
}

.trial-price-details-top-row {
  composes: flex items-center gap-1 from global;
}

.trial-price-today {
  composes: font-medium from global;
  font-size: 14px;
}

.info-icon {
  height: 20px;

  & path {
    fill: var(--c-black-75);
  }
}

.product-purchase-price {
  composes: text-black-75 from global;
  font-size: 12px;
}

.applied-promotion-container {
  composes: flex items-center from global;
}

.applied-promotion-text {
  font-size: 13px;
  line-height: 18px;
  margin-left: 5px;
  color: rgba(26, 26, 26, 0.75);
}

.product-bundle-arrow {
  margin: 0 0 0 6px !important;
  width: 10px !important;
  height: 6px !important;
  transition: all 0.2s linear;

  &.rotate {
    transform: rotate(-180deg);
  }
}

.shipping-options {
  margin: 8px 0;
}

.clearance-info {
  composes: radius-1 p-1 bg-black-5 mt-1 from global;
}

.customized-total-price {
  margin-top: 8px;
}
