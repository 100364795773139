@value bluxomeMediaMd from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  gap: 12px 24px;
  grid-template-areas:
    'copy copy copy copy'
    'image0 image1 image2 image3';

  @media bluxomeMediaMd {
    column-gap: 32px;
    grid-template-columns: repeat(4, minmax(0px, 1fr)) 2fr;
    grid-template-areas: 'image0 image1 image2 image3 copy';
  }
}

.copy-container {
  composes: flex flex-col from global;
  grid-area: copy;
}

.image-container {
  position: relative;
}

.image {
  padding: 4px;
  mix-blend-mode: darken;
}

.remove-button-container {
  position: absolute;
  top: 4px;
  right: 4px;
}

.search-icon-container {
  composes: flex justify-center items-center from global;
  aspect-ratio: 1;
}

.search-icon {
  width: 32px;
  height: 32px;

  @media bluxomeMediaMd {
    width: 44px;
    height: 44px;
  }
}
