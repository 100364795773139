@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.container {
  composes: flex flex-col justify-center pb-3 from global;
  position: relative;
  width: 100%;

  @media mBreakpointSm {
    padding-bottom: 0;
  }
}

.tabs {
  composes: flex self-start from global;
  width: 100%;

  @media mBreakpointSm {
    width: 200px;
    margin-bottom: 28px;
    justify-content: space-between;
  }
}

.standalone .tabs {
  align-self: center;
}

.tab {
  composes: flex-auto text-center font-bold from global;
  cursor: pointer;
  font-size: 22px;
  border-bottom: 4px solid var(--c-separator-light);

  & hr {
    display: none;
  }

  @media mBreakpointSm {
    color: var(--c-black-100);
    font-size: 18px;
    border-bottom: none;
    flex-grow: 0;

    & hr {
      display: block;
    }
  }
}

.tab-selected {
  border-bottom: 4px solid var(--c-primary);

  @media mBreakpointSm {
    border-bottom: none;
  }
}
