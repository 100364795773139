@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.outside-container {
  background-color: #717274;
}

.container {
  composes: flex items-center py-2 from global;
  color: #ffffff;
  font-size: 15px;

  @media mBreakpointSm {
    font-size: 16px;
    padding: 14px 0px;
  }
}

.icon {
  composes: flex from global;
  align-items: center;
}

.link-text {
  composes: font-bold from global;
  border-bottom: 1px solid;
}

.link-text:hover {
  cursor: pointer;
}

.text-container {
  composes: pl-1 from global;

  @media mBreakpointSm {
    padding-left: 13px;
  }
}

.close-container {
  composes: flex from global;
  margin-left: auto;
}

.close {
  justify-content: flex-start;
}

.close:hover {
  cursor: pointer;
}
