@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.values.css';

.wrapper {
  composes: flex items-center py-3 from global;

  @media mBreakpointMd {
    align-items: flex-start;
    padding: 64px 150px;
  }
}

.header {
  font-size: 24px;
  color: var(--c-black-100);
  font-weight: bold;

  @media mBreakpointMd {
    font-size: 40px;
  }
}

.body {
  color: var(--c-black-87);
  font-size: 18px;
  padding-top: 16px;
  line-height: 1.56;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
}

.icon {
  margin-left: 25px;

  @media mBreakpointMd {
    margin-left: 125px;
  }
}

.link-icon {
  width: 84px;
  height: 84px;

  @media mBreakpointMd {
    width: 144px;
    height: 144px;
  }
}
