@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.modal-content {
  composes: flex flex-col from global;
  white-space: pre-line;

  @media mBreakpointSm {
    flex-direction: row;
  }
}

.content-container {
  width: 100%;
  padding: 24px;

  @media mBreakpointSm {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  composes: text-black mb-1 font-bold from global;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.25px;

  @media mBreakpointSm {
    width: 75%;
  }
}

.subtitle {
  composes: text-dark-gray mb-3 from global;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.image-container {
  composes: flex from global;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;

  @media mBreakpointSm {
    min-height: 350px;
    max-height: initial;
    flex-shrink: 1;
  }
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.submit-button {
  composes: whitespace-nowrap from global;
  width: 100%;
}

.fine-print {
  composes: text-dark-gray mt-3 from global;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;

  & a {
    color: var(--c-secondary);
  }
}
