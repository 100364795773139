.section {
  composes: flex flex-col items-center gap-3 my-3 text-center from global;
}

.logo {
  height: 34px;
  width: 176px;
}

.code {
  font-weight: 500;
  font-size: 150px;
  color: #1b242a;
}

.headline {
  composes: m-0 p-0 from global;
  color: #1b242a;
  font-size: 28px;
}
