.list {
  margin: 0;
  padding: 0 0 32px 16px;
  font-size: 15px;
  letter-spacing: 1px;
  color: var(--c-black-87);
  line-height: 1.33;
}

.list-item {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}
