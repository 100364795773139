.digits {
  composes: flex from global;
}

.digit-field {
  composes: mr-2 from global;

  &:last-child {
    margin-right: 0;
  }
}

.digit-field-input {
  border-radius: 8px;
  & input {
    border-radius: 8px;
  }
}

.digit-input {
  composes: text-center from global;
  padding: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
